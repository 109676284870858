@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

:root {
  /* --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primar-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6); */
  --transition: all 400ms ease;

  --container-width-lg: 94%;
  --container-width-md: 92%;
  --container-width-sm: 90%;
}
html {
  scroll-behavior: smooth;
}

.transitioning{
  transition: var(--transition);
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Outfit', sans-serif;
  src: url(../src/fonts/OptimaLTPro-Medium.ttf);
}
.Outfit{
  font-family: 'Inter', sans-serif;
}
.inter{
  font-family: 'Inter', sans-serif;
}
.Inter{
  font-family: 'Inter', sans-serif;
}
.OptimalPro{
  font-family: 'Inter', sans-serif;
}

body {
  font-family: 'Inter', sans-serif;
  /* background: var(--color-bg); */
  /* color: var(--color-white); */
  line-height: 1.7;

}

/* Legacy place */
.legacy1{
  background-image: url(./pages/landing-page/assets/legacyImage1.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  

  
  
}
.legacy2{
  background-image: url(./pages/landing-page/assets/legacyImage2.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.legacy3 {
  background-image: url(./pages/landing-page/assets/legacyImage3.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.legacy4 {
  background-image: url(./pages/landing-page/assets/legacyImage4.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.legacy5 {
  background-image: url(./pages/landing-page/assets/legacyImage5.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.our-story {
  background-image: url(./assets/ourStory.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  
}
.terms {
  background-image: url(./assets/terms.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 70vh;
  
}
.privacy {
  background-image: url(./assets/privacy.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 70vh;
  
}
.blog {
  background-image: url(./assets/blog.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 70vh;
  
}

.blog-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 70vh;
}

.csr {
  background-image: url(./assets/csr.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  
}
.core-values {
  background-image: url(./assets/coreValueeess.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  
  
}
.chisco-details {
  background-image: url(./pages/landing-page/assets/chiscoDetailsImage.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  
}

.rehoboth-details {
  background-image: url(./assets/Rehoboth1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}



.class1 {
  background-image: url(./pages/landing-page/assets/image1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.class2 {
  background-image: url(./pages/landing-page/assets/image2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.class3 {
  background-image: url(./pages/landing-page/assets/image3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.class4 {
  background-image: url(./pages/landing-page/assets/image4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.class5 {
  background-image: url(./pages/landing-page/assets/image5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.class6 {
  background-image: url(./pages/landing-page/assets/image6.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.class7 {
  background-image: url(./pages/landing-page/assets/image5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh; 
}
.drawer-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 400px; /* Adjust the fixed width as needed */
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Adjust opacity as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 20;
}




/* chisco court */

.chisco1 {
  background-image: url(./pages/landing-page/assets/chisco1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.chisco2 {
  background-image: url(./pages/landing-page/assets/chisco2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.chisco3 {
  background-image: url(./pages/landing-page/assets/chisco3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.chisco4 {
  background-image: url(./pages/landing-page/assets/chisco4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.chisco5 {
  background-image: url(./pages/landing-page/assets/chisco5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Rehoboth */

.reho1 {
  background-image: url(./pages/landing-page/assets/reho1.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}
.reho2 {
  background-image: url(./pages/landing-page/assets/reho2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.reho3 {
  background-image: url(./pages/landing-page/assets/reho3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.reho4 {
  background-image: url(./pages/landing-page/assets/reho4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.reho5 {
  background-image: url(./pages/landing-page/assets/reho5.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.giphy {
  background-image: url(./pages/landing-page/assets/giphy.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70vh;
}

.bezalGiphy {
  background-image: url(./pages/landing-page/assets/bezalGiphy.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
  overflow: hidden;
  background-color: aquamarine;
  object-fit: cover;
  
}

.overlay-gif {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
 
}

.two-line-truncation {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.four-line-truncation {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;

}

.dot {
  width: 5px;
  height: 5px;
  border: 4px solid gray; /* Default border for inactive dots */
  background-color: transparent; /* Default background color for inactive dots */
  border-radius: 50%;
  margin: 0 4px;
}

.dot.active {
  border-color: #E9682B; /* Border color for active dot */
  background-color: #E9682B; /* Background color for active dot */
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.line-input {
  border: none;
background: transparent;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.5) ; 
  outline: none;
  padding: 0px; 
  margin: 0px; 
  max-width: 99%;

}

/* Header.css */

video{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.content{
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 display: flex;
 align-items: center;
 justify-content: center;
}


 .overlay{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.3);
 
} 
/* .details-overlay{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.2);
 
} */
/* .overlay2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
 
} */

.overlay4 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
 
}
/* .overlay-rehoboth {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
 
} */

.chisco-heading-bg{
  background: rgba(224, 224, 224, 1);
}


.footer-heading{
  color: rgba(255, 255, 255, 0.5);
}

.footer-text{
  color: rgba(255, 255, 255, 1);
}

.about-line{
  background-color: rgba(51, 51, 51, 0.5);
}

.about-new-text {
  color: rgba(51, 51, 51, 0.7);
}

.masterpiece-head {
  color: rgba(255, 255, 255, 0.9);
}

.homeUnits {
  background-color: rgba(217, 217, 217, 1);
}
.mobileGray {
  background-color: transparent;
}

.spinner {
  animation: spin 4s ease-in-out infinite; /* Increase duration and use ease-in-out timing function */
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-zoom:hover {
  transform: scale(0.9); /* You can adjust the scale factor for the desired zoom level */
  transition: transform 0.3s ease-in-out; /* You can adjust the duration and easing function */
}


.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnail {
  width: 200px;
  height: 200px;
  margin: 10px;
  cursor: pointer;
}

.lightbox {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

.lightbox-content {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.lightbox img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* You can use 'cover' if you want it to cover the entire container */
}


.nav-buttons button {
  background-color: transparent;
  border: none;
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
}

.nav-buttons button:hover {
  color: #ccc;
}

/* Add this to your CSS file or use a CSS-in-JS solution like styled-components */





.thumb {
  transition: transform 0.3s ease-in-out;
}

/* .thumb:hover {
  transform: scale(1.2);
} */

.thumb.active {
  transform: scale(1.3);
}

.thumb img {
  transition: transform 0.3s ease-in-out;
}

.thumb.hovered img {
  transform: scale(1.2);
}

.ant-dropdown-menu {
  background-color :  gray !important;  /* Customize background color */

}

.ant-dropdown-menu-item {
  background-color: transparent !important; /* Set background color to transparent */
}

.ant-dropdown-menu-item:hover {
  background-color: #383838; /* Customize hover background color */
}
.blog-content *{
  margin-top: 1rem !important;
}
.blog-content h5 {
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
}

.blog-content img {
  width: 100%;
  object-fit: cover;
  margin: 2rem 0 !important;
}

.blog-content ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

.blog-content ul {
  list-style-type: disc;
  margin-left: 1rem;
  font-weight: bold;
}

.blog-content ol ul {
  list-style-type: disc;
  margin-left: 2rem;
  font-weight: normal;
  margin-top: auto !important;
}

.blog-content li {
  margin-top: auto !important;
}

.small-video-player {
  background: url(./assets/AboutChisco.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


/* MEDIA QUERIES FOR MEDIUM DEVICES */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
  .line-input {
    border: none;
    background: transparent;
    border-bottom: 0.8px solid white; 
    outline: none;
    padding: 0px; 
    margin: 0px; 
    width: 250px; 
  }
}
/* MEDIA QUERIES FOR small DEVICES */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }

  .lightbox img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* You can use 'cover' if you want it to cover the entire container */
  }
  
  .line-input {
    border: none;
    background: transparent;
    border-bottom: 0.8px solid white; 
    outline: none;
    padding: 0px; 
    margin: 0px; 
    width: 200px; 
  }#606060
  .giphy {
    background-image: url(./pages/landing-page/assets/giphy.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 20vh;

  }
  .mobileGray {
    background-color: rgba(217, 217, 217, 1);
  }
  
  /* .content{
    position: absolute;
    top:-35%;
    display: flex;
  
   } */
}
@media only screen and (max-width: 315px)  { 
  .Smart-watch-heading{
    font-size: 10px;
  }
  .Smart-watch-text{
    font-size: 8px;
  }
  

 }
